import {motion} from "framer-motion";
import {ActionButton, AddToCart} from "../../../components/buttons";
import React, {useEffect} from "react";
import {useStoreState} from "pullstate";
import {AppCall, getCall, getCallStates, getProduct, getVapi, resetTranscript, updateCall,} from "../../../stores";
import {ASSISTANT_NAME} from "../../../config";
import {MicOffIcon, MicOnIcon, TelOffIcon, TelOnIcon} from "../../../components/icons";
import {AIAssistant} from "../../../assistant";

interface Props extends React.ComponentProps<typeof motion.div> {
    //
}

export const CallButtons: React.FC<Props> = ({...props}) => {
    //--- Start States
    const vapi = useStoreState(AppCall, getVapi);
    const product = useStoreState(AppCall, getProduct);
    const call = useStoreState(AppCall, getCall);
    const states = useStoreState(AppCall, getCallStates);
    //--- End States

    //--- Start Getters
    // TODO: Add getters here
    //--- End Getters

    //--- Start Actions
    const startCall = async () => {
        AppCall.update((state) => ({
            ...state,
            isCalling: true,
        }));

        const assistant = new AIAssistant(product, ASSISTANT_NAME);

        try {
            console.log("Call started");
            const details = assistant.create();
            const res = await vapi.start(details);
            if (res) {
                resetTranscript();
                updateCall(res);
                console.log("Call ID: ", res.id, "Customer ID: ", res.customerId);
            }
        } catch (err) {
            AppCall.update((state) => ({
                ...state,
                isCalling: false,
            }));
        } finally {
            //
        }
    };

    const endCall = () => {
        vapi.stop();
        AppCall.update((state) => ({
            ...state,
            isCalling: false,
            inCall: false,
        }));
    };

    const muteUnmuteCall = () => {
        const muted = !(vapi.isMuted());
        vapi.setMuted(muted);
        AppCall.update((state) => ({
            ...state,
            muted,
        }));
    };
    //--- End Actions

    //--- Start watchers
    useEffect(() => {
        vapi.on("speech-start", () => {
            if (!states.inCall) {
                AppCall.update((state) => ({
                    ...state,
                    inCall: true,
                }));
            }
        });

        vapi.on("call-end", () => {
            AppCall.update((state) => ({
                ...state,
                inCall: false,
                isCalled: false,
                showAddToCart: false,
                muted: false,
            }));
        });
    }, [vapi, call, states.inCall]);
    //--- End watchers

    return (
        <motion.div className="grid grid-cols-12 gap-4" {...props}>
            <div className="col-span-6">
                <ActionButton
                    color={states.callInProgress ? "danger" : "success"}
                    variant={states.callInProgress ? "default" : "outlined"}
                    icon={states.callInProgress ? <TelOffIcon/> : <TelOnIcon className="w-5 h-5"/>}
                    onClick={states.callInProgress ? endCall : startCall}
                >
                    {states.callInProgress ? "Fin d'appel" : "Lancer l'appel"}
                </ActionButton>
            </div>
            <div className={`col-span-6 text-sm ${states.inCall ? "block" : "hidden"}`}>
                <ActionButton
                    color="tertiary"
                    variant="outlined"
                    icon={states.muted ? <MicOffIcon/> : <MicOnIcon/>}
                    onClick={muteUnmuteCall}
                >
                    {states.muted ? "Réactiver" : "Couper"} l'appel
                </ActionButton>
            </div>
            <div
                className={`col-span-full ${
                    states.showAddToCart && states.callInProgress ? "block" : "hidden"
                }`}
            >
                <AddToCart variant="outlined"/>
            </div>
        </motion.div>
    );
};
