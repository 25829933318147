import React, { useMemo } from "react";
import { ButtonColor, ButtonVariant } from "../../interfaces";
import {
    AnimationControls,
    motion,
    TargetAndTransition,
    VariantLabels,
} from "framer-motion";

interface Props extends React.ComponentProps<typeof motion.button> {
    icon?: any;
    children?: any;
    variant?: ButtonVariant;
    color?: ButtonColor;
    animate?:
        | boolean
        | AnimationControls
        | TargetAndTransition
        | VariantLabels
        | undefined;
    buttonClass?: string;
}

export const ActionButton: React.FC<Props> = ({
                                                  icon,
                                                  variant = "default",
                                                  color = "primary",
                                                  children,
                                                  animate,
                                                  buttonClass = "",
                                                  ...props
                                              }) => {
    const colorVariant = useMemo(() => {
        switch (color) {
            case "primary":
                return variant === "outlined"
                    ? "border border-black hover:bg-black text-black"
                    : "bg-black";
            case "secondary":
                return variant === "outlined"
                    ? "border border-pink-500 hover:bg-pink-500 text-pink-500"
                    : "bg-pink-500";
            case "success":
                return variant === "outlined"
                    ? "border border-green-500 hover:bg-green-500 text-green-500"
                    : "bg-green-500";
            case "danger":
                return variant === "outlined"
                    ? "border border-red-500 hover:bg-red-500 text-red-500"
                    : "bg-red-500";
            case "tertiary":
                return variant === "outlined"
                    ? "border border-gray-500 hover:bg-gray-500 text-gray-500"
                    : "bg-gray-500";
            default:
                return variant === "outlined"
                    ? "border border-black hover:bg-black text-black"
                    : "bg-black";
        }
    }, [variant, color]);

    const variantText = useMemo(() => {
        switch (variant) {
            case "outlined":
                return "hover:text-white";
            default:
                return "text-white";
        }
    }, [variant]);

    return (
        <motion.button
            {...props}
            animate={animate}
            transition={{ duration: 1.5 }}
            className={`py-2 px-4 rounded-full ${buttonClass} ${
                variant === "outlined" ? "" : "hover:bg-opacity-80"
            } ${colorVariant} ${variantText}`}
        >
      <span className="flex items-center justify-center space-x-2">
        <span>{icon}</span>
        <span className="text-sm">{children}</span>
      </span>
        </motion.button>
    );
};
