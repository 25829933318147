import {Product} from "../interfaces";

export const products: Product[] = [
    {
        id: 1,
        ref: 'BWT AQUA + POMPE',
        name: "ECOSOFT STANDARD FILTRE D'OSMOSE INVERSE AVEC POMPE",
        shortName: 'Osmoseur domestique',
        image: 'https://www.bwt.ma/wp-content/uploads/2020/09/ECOSOFT-AVEC-POMPE-ET-MINERALISATION-640x640.png',
        category: 'osmoseur',
        slug: 'osmoseur-domestique-1',
        price: '2000 MAD',
        features: [
            'Il est conçu pour les systèmes d’approvisionnement en eau à basse pression',
            'Les 5 étapes de la purification de l’eau',
            '9 L d’eau potable par heure',
            'L’élément de membrane Ecosoft',
            'Le goût de l’eau est délicieux grâce au filtre à charbon en fibres de noix de coco',
            'Le boîtier du filtre est composé de plastique alimentaire',
            'L’assurance d’une partie du fabricant — tous les éléments sont testés et approuvés pour une application domestique',
        ],
    },
    {
        id: 2,
        ref: 'BWT AQUA',
        name: "ECOSOFT STANDARD FILTRE D'OSMOSE INVERSE AVEC MINERALISATION",
        shortName: 'Osmoseur domestique',
        image: 'https://www.bwt.ma/wp-content/uploads/2020/09/ECOSOFT-AVEC-POMPE-ET-MINERALISATION-640x640.png',
        category: 'osmoseur',
        slug: 'osmoseur-domestique-2',
        price: '2200 MAD',
        features: [
            'Il est conçu pour les systèmes d’approvisionnement en eau à basse pression',
            'Le filtre est stable et simple en maintenance grâce au cadre métallique',
            'Les 6 étapes de la purification de l’eau, y compris la minéralisation',
            '9 L d’eau potable par heure',
            'L’élément de membrane Ecosoft',
            'Le goût de l’eau est délicieux grâce au filtre à charbon de coco',
            'Le boîtier du filtre est composé de plastique alimentaire',
            'L’assurance d’une partie du fabricant — tous les éléments sont testés et approuvés pour une application domestique',
        ],
    },
];
