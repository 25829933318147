import React from "react";
import { useNavigate } from "react-router-dom";
import { ActionButton } from "../components/buttons";

const Error404: React.FC = () => {
    const navigate = useNavigate();
    const goBack = () => {
        navigate("/");
    };
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <h1 className="font-bold text-3xl">404</h1>
            <h2 className="text-xl my-8">Page Does Not Exist</h2>
            <div className="w-40">
                <ActionButton color="primary" variant="outlined" onClick={goBack}>
                    Go Back Home
                </ActionButton>
            </div>
        </div>
    );
};

export default Error404;
