import React, {useState} from "react";
import {ActionButton} from "../../../components/buttons";
import {useStoreState} from "pullstate";
import {AppCall, getProduct} from "../../../stores";
import {SupportIcon, TelOnIcon} from "../../../components/icons";
import Popup from "reactjs-popup";
import {AnimatePresence, motion} from "framer-motion";
import {CallVolumeLevel} from "./CallVolumeLevel";
import {CallButtons} from "./CallButtons";
import {CallTranscript} from "./CallTranscript";
import {CallChat} from "./CallChat";


interface Props extends React.ComponentProps<typeof ActionButton> {
    children?: React.ReactNode;
    onCallStarted?: () => void;
    onCallEnded?: () => void;
}


const TalkToUs: React.FC<Props> = ({onCallStarted, onCallEnded, children, ...props}) => {
    const [open, setOpen] = useState(false);
    const product = useStoreState(AppCall, getProduct);

    const tabs = [
        {
            icon: <TelOnIcon className="w-6 h-6"/>,
            label: "Call",
            component: "Lorem ipsum sit dolor amet",
        },
        {
            icon: <SupportIcon className="w-6 h-6"/>,
            label: "Transcript",
            component: "Lorem ipsum sit dolor amet 2",
        },
    ];

    const [selectedTab, setSelectedTab] = useState(tabs[0]);

    const TalkToUs = () => {
        onCallStarted && onCallStarted();
    };

    return (
        <div>
            <Popup trigger={
                <ActionButton
                    color="secondary"
                    variant="outlined"
                    {...props}
                    onClick={TalkToUs}
                >
                    {children ? children : "Appelez-nous"}
                </ActionButton>
            }
                   modal
                   nested
                   closeOnDocumentClick={false}
                   closeOnEscape={false}
                   onOpen={() => setOpen(true)}
                   onClose={() => setOpen(false)}
                   open={open}
            >
                <div className="m-4 w-screen max-w-lg">
                    <div className="bg-white shadow-lg">
                        <AnimatePresence mode="wait">
                            <motion.div
                                className="p-4"
                                key={selectedTab ? selectedTab.label : "empty"}
                                initial={{y: 10, opacity: 0}}
                                animate={{y: 0, opacity: 1}}
                                exit={{y: -10, opacity: 0}}
                                transition={{duration: 0.2}}
                            >
                                <div
                                    className={`col-span-full ${
                                        selectedTab.label === "Transcript" ? "block" : "hidden"
                                    }`}
                                >
                                    <CallTranscript/>

                                    {/*<CallChat/>*/}
                                </div>
                                <div
                                    className={`grid grid-cols-12 gap-4 ${
                                        selectedTab.label === "Call" ? "block" : "hidden"
                                    }`}
                                >
                                    <div className="col-span-12 flex flex-col justify-center items-center">
                                        <SupportIcon className="w-20 h-20 text-gray-500 my-4"/>
                                        <CallVolumeLevel/>
                                    </div>
                                    <div className="col-span-12 text-sm text-center">
                                        Notre assistant répondra à vos questions sur{" "}
                                        <span className="font-semibold">{product.name}</span>
                                    </div>
                                    <div className="col-span-full">
                                        <CallButtons/>
                                    </div>
                                    <div className="col-span-full">
                                        <CallChat />
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                        <div className="">
                            <ul className="flex justify-between">
                                {tabs.map((item) => (
                                    <li
                                        key={item.label}
                                        className={`w-full h-full text-center cursor-pointer px-4 py-2 flex items-center space-x-2 ${
                                            item.label === selectedTab.label
                                                ? "selected bg-green-50 border-b-2 border-green-500"
                                                : ""
                                        }`}
                                        onClick={() => setSelectedTab(item)}
                                    >
                                        <span>{item.icon}</span> <span>{item.label}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </Popup>
        </div>
    );
};

export default TalkToUs;
