interface Props extends React.SVGProps<SVGSVGElement> {}

export const SupportIcon: React.FC<Props> = ({ ...props }) => {
  return (
    <svg
      {...props}
      viewBox="0 0 1024 1024"
      // className="icon w-5 h-5"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M565.370023 772.592126c-57.039628 44.171437-100.880349 6.115667-105.80831 1.507159-8.99688-8.535108-23.163358-8.270946-31.763994 0.660406-8.644664 8.952853-8.381525 23.207385 0.572352 31.852048 15.26613 14.727567 45.315117 32.094709 82.183179 32.09471 25.143552 0 53.43248-8.062073 82.402291-30.499496 9.833395-7.621802 11.636457-21.766778 4.025917-31.599149-7.634089-9.834419-21.800566-11.627242-31.611435-4.015678zM353.90801 534.644248c-18.654167 0-33.788216 15.122786-33.788215 33.788216v45.050954c0 18.664406 15.134049 33.788216 33.788215 33.788216s33.788216-15.12381 33.788216-33.788216v-45.050954c0-18.664406-15.134049-33.788216-33.788216-33.788216zM646.739213 534.644248c-18.654167 0-33.788216 15.122786-33.788216 33.788216v45.050954c0 18.664406 15.134049 33.788216 33.788216 33.788216s33.788216-15.12381 33.788216-33.788216v-45.050954c0-18.664406-15.134049-33.788216-33.788216-33.788216z"
        fill="currentColor"
      />
      <path
        d="M871.993985 455.805078c0-191.994881-168.412754-360.407634-360.407635-360.407635s-360.407634 168.412754-360.407634 360.407635c-24.777001 0-45.050954 20.271906-45.050954 45.050954v157.67834c0 24.777001 20.273953 45.050954 45.050954 45.050954h22.525477c24.777001 0 45.050954-20.273953 45.050954-45.050954v-90.101908a23.549362 23.549362 0 0 0 1.231734-0.032765c175.523637-9.523157 298.44619-137.44227 358.872831-217.791671 37.21516 32.154095 136.422481 110.490537 232.969747 125.673732-4.651511 7.084263-7.411906 15.512887-7.411906 24.574272v157.67834c0 10.760011 3.978818 20.536068 10.333051 28.30531-11.008815 82.724815-82.915257 149.847665-214.276673 199.74774-3.798615-14.498216-16.92892-25.323756-32.573888-25.323756h-112.627386c-18.583519 0-33.788216 15.204697-33.788215 33.788216s15.204697 33.788216 33.788215 33.788216h112.627386c12.477067 0 23.304654-6.931704 29.156159-17.058953 140.968532-51.288464 221.16435-122.013343 238.379957-210.670549 4.444686 1.477467 9.10746 2.474731 14.031325 2.47473h22.525477c24.777001 0 45.050954-20.273953 45.050954-45.050954v-157.67834c0.001024-24.779049-20.272929-45.050954-45.04993-45.050954z m-259.483258-199.957637c-10.910522-5.983586-24.615227-1.980194-30.576288 8.941591-1.339242 2.44811-136.912922 245.37719-363.179292 258.517734v-22.450734c0-17.009807-9.66343-31.715872-23.669157-39.378629 0.494537-1.856304 1.14368-3.659366 1.14368-5.672325 0-167.995009 147.361671-315.35668 315.35668-315.35668 160.277985 0 301.446174 134.190411 314.109588 292.405266-89.171197-10.391412-189.215032-91.837394-220.761963-119.358407 9.823156-15.07364 15.430976-25.109764 16.508103-27.081767 5.983586-10.911546 1.97917-24.593726-8.931351-30.566049z"
        fill="currentColor"
      />
    </svg>
  );
};
