import { ActionButton } from "./ActionButton";
import React from "react";

interface Props extends React.ComponentProps<typeof ActionButton> {
    children?: any;
    onAddedToCart?: () => void;
}

export const AddToCart: React.FC<Props> = ({
                                               children,
                                               animate,
                                               onAddedToCart,
                                               ...props
                                           }) => {
    const addToCart = () => {
        onAddedToCart && onAddedToCart();
    };

    return (
        <ActionButton {...props} onClick={addToCart}>
            {children ? children : "Ajouter Au Panier"}
        </ActionButton>
    );
};
