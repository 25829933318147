import React, {useCallback, useEffect, useState} from "react";
import {timeout} from "../utils";
import {CircleLoader} from "react-spinners";
import {useStoreState} from "pullstate";
import {AppCall, getProducts} from "../stores";
import ProductCard from "./products/ProductCard";

export const Home: React.FC = () => {
    const [loading, setLoading] = useState<boolean>(false);
    const products = useStoreState(AppCall, getProducts);

    const init = useCallback(async () => {
        setLoading(true);
        await timeout(2000, async () => {
            setLoading(false);
        });
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    return loading ? (
        <div className="flex flex-col items-center justify-center h-screen w-full text-[lightBlue]">
            <CircleLoader size="70" color="lightBlue"/>
            <span className="block mt-4">Loading...</span>
        </div>
    ) : (
        <div className="max-w-5xl mx-auto h-screen flex flex-wrap items-center justify-center py-12">
            {products.map((product) => (
                <ProductCard product={product} key={product.id}/>
            ))}
        </div>
    );
}
