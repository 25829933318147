import React from 'react';
import Error404 from "./views/Error404";
import {Route, Routes} from 'react-router-dom';
import {Home} from "./views/Home";
import SingleProduct from "./views/products/SingleProduct";

function App() {
    return (
        <Routes>
            <Route index={true} element={<Home/>}/>

            <Route path="/:category/:slug" element={<SingleProduct/>}/>

            <Route path="*" element={<Error404/>}/>
        </Routes>
    );
}

export default App;
