import React, { useMemo } from "react";
import { Product } from "../../interfaces";

interface Props {
    product: Product;
}

const ProductCard: React.FC<Props> = ({ product }) => {
    // const miniDescription = useMemo(() => _.truncate(product.description ?? '', {length: 80}), [product])
    const productUrl = useMemo(
        () => `/${product.category}/${product.slug}`,
        [product]
    );

    return (
        <a className="flex flex-col w-64 m-4 justify-start" href={productUrl}>
            <img
                src={product?.image}
                className="bg-gray-100 bg-opacity-50"
                alt={product.name}
            />
            <div className="p-2 text-wrap break-words">
                <div
                    className="font-medium truncate text-blue-900"
                    title={product.name}
                >
                    {product.shortName}
                </div>
                <div className="break-words text-xs text-gray-400 py-1">
                    Réf. {product.ref}
                </div>
                <div className="text-lg font-medium">{product.price}</div>
            </div>
        </a>
    );
};

export default ProductCard;
