import axios, { AxiosRequestConfig } from "axios";
import { FAQ_ENDPOINT, IP_ADDRESS_ENDPOINT, PRODUCTS_ENDPOINT, TRANSCRIPT_ENDPOINT } from "../config";
import {
  FAQ,
  GetFAQsResponse,
  GetProductResponse,
  GetProductsResponse,
  Product,
  TranscriptRequestData,
} from "../interfaces";

export class MakeRequest {
  static get = (url: string, config?: AxiosRequestConfig) =>
    axios.get(url, config);
  static post = (url: string, data?: any, config?: AxiosRequestConfig) =>
    axios.post(url, data, config);
}

export class ProductRequest {
  static index = async (): Promise<Product[]> => {
    const res = await axios.post(PRODUCTS_ENDPOINT);
    const response = res.data as GetProductsResponse;
    console.log(response);
    return response.results[0].result.products;
  };

  static find = async (productId: string | number): Promise<Product> => {
    const message = {
      toolCalls: [
        {
          id: "",
          type: "function",
          function: {
            name: "get_product",
            arguments: {
              productId,
            },
          },
        },
      ],
    };

    const res = await MakeRequest.post(PRODUCTS_ENDPOINT, {
      message,
    });
    const response = res.data as GetProductResponse;
    // console.log('Single: ', response.results[0].result);
    return response.results[0].result.product;
  };

  static getAlternatives = async (productId: string): Promise<Product[]> => {
    const res = await ProductRequest.index();
    return res.filter((product) => product.id !== productId);
  };

  static getFAQs = async (): Promise<FAQ[]> => {
    const res = await MakeRequest.post(FAQ_ENDPOINT);
    const response = res.data as GetFAQsResponse;
    return response.results[0].result.faqs;
  };
}

export class TranscriptRequest {
  static create = async (transcript: TranscriptRequestData) => {
    // console.log(transcript.product_id, transcript.category);u
    const response = await MakeRequest.post(TRANSCRIPT_ENDPOINT, transcript);
    return response.data;
  };
}

export class ClientRequest {
  static getClientIp = async () => {
    const response = await MakeRequest.get(IP_ADDRESS_ENDPOINT);
    const ips = response.data.clientIp.split(",");
    return ips[0] || "";
  };
}
