import {Store} from "pullstate";
import {CallStore, ConversationTranscript, Product} from "../../interfaces";
import Vapi from "@vapi-ai/web";
import {products} from "../../data";
import {VAPI_PUBLIC_KEY} from "../../config";
import {Call} from "@vapi-ai/web/dist/api";

export const AppCall = new Store<CallStore>({
    inCall: false,
    isCalling: false,
    muted: false,
    showAddToCart: false,
    call: undefined,
    product: products[0],
    products: products,
    transcripts: [],
    vapi: new Vapi(VAPI_PUBLIC_KEY)
});

export const updateTranscript = (transcript: ConversationTranscript) => {
    AppCall.update((state) => {
        const last = state.transcripts.length ? state.transcripts[state.transcripts.length - 1] : null;
        const skip =
            last?.transcript === transcript.transcript &&
            last?.role === transcript.role;
        if (!skip) {
            return {
                ...state,
                transcripts: [...state.transcripts, transcript],
            };
        }
    });

    //   AppCall.update((state) => ({
    //     transcripts: [...state.transcripts, transcript],
    //   }));
};

export const resetTranscript = () => {
    AppCall.update((state) => ({
        ...state,
        transcripts: [],
    }));
};

export const replaceProduct = (product: Product) => {
    AppCall.update((state) => ({
        ...state,
        product,
    }));
};

export const updateCall = (call: Call) => {
    AppCall.update((state) => ({
        ...state,
        call,
    }));
};
