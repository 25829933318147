import {CallMessage, ConversationTranscript} from "../../../interfaces";
import {motion} from "framer-motion";
import React, {useEffect, useState} from "react";
import {useStoreState} from "pullstate";
import {AppCall, getTranscripts, getVapi, updateTranscript} from "../../../stores";
import {TranscriptItem} from "./TranscriptItem";

interface Props extends React.ComponentProps<typeof motion.div> {
    //
}

export const CallTranscript: React.FC<Props> = ({...props}) => {
    const [messages, setMessages] = useState<CallMessage[]>([]);

    const vapi = useStoreState(AppCall, getVapi);

    const transcripts = useStoreState(AppCall, getTranscripts);

    useEffect(() => {
        vapi.on("message", (message: ConversationTranscript) => {
            if (message.type === "transcript" && message.transcriptType === "final") {
                updateTranscript(message);
            }
            const res = [...messages, message];
            setMessages(res);
            console.log(message);
        });
    }, [messages, props, vapi]);

    return (
        <div className="my-4 w-full overflow-auto max-h-60">
            <div className="font-semibold">Transcription de l'appel</div>
            {transcripts.map((transcript, index) => (
                <TranscriptItem key={index} transcript={transcript}/>
            ))}
        </div>
    );
};
