import {useStoreState} from "pullstate";
import React, {useEffect, useState} from "react";
import {AppCall, getVapi} from "../../../stores";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    //
}

export const CallVolumeLevel: React.FC<Props> = ({...props}) => {
    const [volume, setVolume] = useState(0);
    const numBars = 10;

    const vapi = useStoreState(AppCall, getVapi);

    useEffect(() => {
        vapi.on("volume-level", (value: number) => {
            setVolume(value);
        });
    }, [vapi]);

    return (
        <div {...props}>
            <div className="flex">
                {Array.from({length: numBars}, (_, i) => (
                    <div
                        key={i}
                        className={`w-4 h-4 m-0.5 rounded ${
                            i / numBars < volume
                                ? " bg-green-500"
                                : " bg-gray-300 bg-opacity-40"
                        }`}
                    />
                ))}
            </div>
        </div>
    );
};
