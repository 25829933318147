import { CreateFunctionToolDTO } from "@vapi-ai/web/dist/api";
import { PRODUCTS_ENDPOINT, FAQ_ENDPOINT } from "../config";

export const getAllProducts: CreateFunctionToolDTO = {
  type: "function",
  messages: [
    {
      type: "request-start",
      content: "Please wait while I get some alternative products for you",
    },
    {
      type: "request-complete",
      role: "system",
      content:
        "After retrieving all products. Give feedback to the user about the alternative products available. Do not give descriptions initially until you are asked.",
    },
    {
      type: "request-failed",
      content: "I couldn't get the products right now.",
    },
    {
      type: "request-response-delayed",
      content:
        "It appears there is some delay in communication with our servers.",
      timingMilliseconds: 2000,
    },
  ],
  function: {
    name: "get_all_products",
    parameters: {
      type: "object",
      properties: {},
    },
    description:
      "Retrieves all the products to provide details about alternative products if the client asks for alternatives or other products",
  },
  async: false,
  server: {
    url: PRODUCTS_ENDPOINT,
  },
};

export const getCurrentProduct: CreateFunctionToolDTO = {
  type: "function",
  messages: [
    {
      type: "request-start",
      content: "Give me a moment to pull out the details of the product",
    },
    {
      type: "request-complete",
      role: "system",
      content:
        "Unless other information is asked by the user, use the information received to give a brief summary of the product using the price, features and technical details. No need to read out the whole description to the user. Be as conscise and accurate as possible.",
    },
    {
      type: "request-failed",
      content: "I couldn't get the product information right now.",
    },
    {
      type: "request-response-delayed",
      content:
        "It appears there is some delay in communication with our servers.",
      timingMilliseconds: 2000,
    },
  ],
  function: {
    name: "get_product",
    parameters: {
      type: "object",
      properties: {
        productId: {
          type: "string",
          description: "This is the id of the product",
        },
      },
    },
    description:
      "Retrieves the current product at the beginning of the conversation from the products API with the parameter 'productId' which will contain the id of the product.",
  },
  async: false,
  server: {
    url: PRODUCTS_ENDPOINT,
  },
};

export const getFAQs: CreateFunctionToolDTO = {
  type: "function",
  messages: [
    {
      type: "request-start",
      content:
        "Give me a moment to go through our FAQs if there are any answers matching your question",
    },
    {
      type: "request-complete",
      role: "system",
      content:
        "Use the information from the FAQs to answer the specific question of the user. Be concise with your answers. If the answer to to trhe question is not available, ask the user if they would like to request a callback with more information.",
    },
    {
      type: "request-failed",
      content:
        "I couldn't details about your question right now. Would you like to request a callback to get more details.",
    },
    {
      type: "request-response-delayed",
      content:
        "It appears there is some delay in communication with our servers.",
      timingMilliseconds: 2000,
    },
  ],
  function: {
    name: "get_faqs",
    parameters: {
      type: "object",
      properties: {},
    },
    description:
      "Retrieves all the Frequently asked questions at the beginning of the conversation from the FAQs. This will help to answer questions about our products",
  },
  async: false,
  server: {
    url: FAQ_ENDPOINT,
  },
};
