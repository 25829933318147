import { FAQ, Product } from "../interfaces";

export const generateProductDetails = (product: Product): string => {
  const features = product.features.join("\n");
  // const technicalDetails = product.technical_details
  //   .map((item: object) => {
  //     // Return a string with the key and value separated by a colon
  //     return `${Object.keys(item)} - ${Object.values(item)}`;
  //   })
  //   .join("\n");
  let response = "";
  response += `Product: ${product.name}\n`;
  response += `Category: ${product.category}\n`;
  response += `Price: €${product.price} (Prices incl. VAT)\n`;
  // response += `Description: ${product.description}\n`;
  response += `Features: \n${features}\n`;
  // response += `Technical Details: \n${technicalDetails}`;
  return response;
};

export const convertFAQsToString = (faqs: FAQ[]): string => {
  const mapped = faqs.map((faq) => {
    return `${faq.question} - ${faq.answer}`;
  });
  return mapped.join("\n\n");
};
