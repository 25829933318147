export const timeout = async <T>(timeoutMs: number, callback?: () => Promise<T>, ) => {
    try {
        return await Promise.race([
            new Promise<void>((resolve, reject) => {
                setTimeout(() => {
                    callback?.()
                }, timeoutMs);
            }),
        ]);
    } catch (error) {
        // Handle the timeout error here
        console.error('Timeout:', error);
        throw error; // Re-throw the error for further handling
    }
};
