import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AppCall} from "./stores";
import {products} from "./data";
import Vapi from "@vapi-ai/web";
import {VAPI_PUBLIC_KEY} from "./config";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

AppCall.update((s) => {
    s.products = products;
    s.product = products[0];
    s.transcripts = [];
    s.call = undefined;
    s.vapi = new Vapi(VAPI_PUBLIC_KEY);
})

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
