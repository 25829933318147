import {ASSISTANT_NAME} from "../../../config";
import {ConversationTranscript} from "../../../interfaces";
import React from "react";

interface Props {
    transcript: ConversationTranscript;
}

export const TranscriptItem: React.FC<Props> = ({ transcript }) => {
    return (
        <div className={`relative bg-opacity-50 my-4 rounded`}>
            <div
                className={`flex flex-wrap ${
                    transcript.role === "assistant" ? "justify-start" : "justify-end"
                }`}
            >
        <span
            className={`rounded p-2 ${
                transcript.role === "assistant"
                    ? "bg-gray-200 text-left"
                    : "bg-blue-200 text-right"
            }`}
        >
          <span className="text-xs block font-medium underline">
            {`${
                transcript.role === "assistant" ? ASSISTANT_NAME : transcript.role
            }`}
          </span>
          <span className="block pt-1">{transcript.transcript}</span>
        </span>
            </div>
        </div>
    );
};
