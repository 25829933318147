import {createSelector} from "reselect";
import {CallStore} from "../../interfaces";

const getState = (state: CallStore) => state;

export const getCall = createSelector(
    [getState],
    (state: CallStore) => state.call
);

export const getTranscripts = createSelector(
    [getState],
    (state: CallStore) => state.transcripts
);

export const getVapi = createSelector(
    [getState],
    (state: CallStore) => state.vapi
);

export const getProducts = createSelector(
    [getState],
    (state: CallStore) => state.products
);

export const getProduct = createSelector(
    [getState],
    (state: CallStore) => state.product
);

export const getCallInProgress = createSelector(
    [getState],
    (state: CallStore) => state.call !== null
);

export const getCallId = createSelector(
    [getState],
    (state: CallStore) => state.call?.id
);

export const getCallStates = createSelector(
    [getState],
    (state: CallStore) => ({
        isCalling: state.isCalling,
        inCall: state.inCall,
        muted: state.muted,
        showAddToCart: state.showAddToCart,
        callInProgress: state.isCalling || state.inCall,
    })
);

export const getCallDetails = createSelector(
    [getState],
    (state: CallStore) => state
);
